import React, { useEffect } from 'react';
import { graphql, PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';

import Layout from '../layouts/Layout';
import SEO from '../components/SEO';

const StyledTerms = styled.div`
  width: 40rem;
  max-width: 85vw;
  margin: 3rem auto calc(3rem + 50px);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 p,
  li {
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 1.25rem;
  }

  @media (max-width: ${props => props.theme.breakMedium}) {
    margin: calc(2rem + 50px) auto 2rem;
  }
`;

interface TermsPageProps extends PageProps {
  data: {
    file: {
      childMdx: {
        id: string;
        frontmatter: {
          terms: string;
        };
      };
    };
  };
}

const TermsPage: React.FC<TermsPageProps> = function (props) {
  const {
    data: {
      file: {
        childMdx: { frontmatter },
      },
    },
    location,
  } = props;

  useEffect(() => {
    document.body.style.background = '#fff';
  });

  return (
    <Layout currPage={location.pathname}>
      <SEO title="Terms | Courtney Barnett" />
      <StyledTerms>
        <MDXRenderer>{frontmatter.terms}</MDXRenderer>
      </StyledTerms>
    </Layout>
  );
};

export default TermsPage;

export const pageQuery = graphql`
  query termsQuery {
    file(sourceInstanceName: { eq: "terms" }) {
      childMdx {
        id
        frontmatter {
          terms
        }
      }
    }
  }
`;
